import { Reducer } from "redux";
import { IndividualActionTypes, IndividualState, createEmptyIndividual } from "./types";
import { createEmptyOs } from "../../models";

export enum Clientes {
    FALABELLA = 'FALABELLA',
    NATURA = 'NATURA',
    HUAWEI = 'HUAWEI',
  }
  const randomEnumValue = (enumeration: { [x: string]: any; }) => {
    const values = Object.keys(enumeration);
    const enumKey = values[Math.floor(Math.random() * values.length)];
    return enumeration[enumKey];
  }
export const initialState: IndividualState = {
    data: createEmptyIndividual(),
    errors: undefined,
    loading: false
};
const reducer: Reducer<IndividualState> = (state = initialState, action) => {
    switch (action.type) {
        case IndividualActionTypes.LOADING: {
            console.log('Cleaar OS ');
            return {
                loading: true,
                data: state.data
            }
        }
        case IndividualActionTypes.CLEAR_MESSAGE: {
            console.log('Clear Message');
            return {
                loading: false,
                data: state.data,
                message: undefined,
                errors: undefined
                
            }
        }
        case IndividualActionTypes.SET_OS: {
            console.log('SET OS ', action.payload);
            return {
                loading: false,
                data: {
                    osSearch: '',
                    os: action.payload
                }
            }
        }
        case IndividualActionTypes.FETCH_SUCCESS: {
            console.log('Cleaar OS ', action.payload);
            return {
                loading: false,
                data: {
                    osSearch: '',
                    os: createEmptyOs()
                },
                message: 'OS ' + state.data.os.ordenServicio.numeroOS + '/' + state.data.os.ordenServicio.nombreEmbarcador + ' CUBICADO!'
            }
        }
        case IndividualActionTypes.FETCH_REQUEST_OS: {
            console.log('Cargando OS ', action.payload);
            if (action.payload > 0) {
                console.log('Cargando OS');
                return {
                    loading: false,
                    data: {
                        osSearch: 0,
                        os: {
                            id: action.payload,
                            origen: randomEnumValue(Clientes)
                        }
                    }
                };

            } else {
                console.log('VACIO');
                return state;
            }
        }
        case IndividualActionTypes.CLEAR_OS: {
            console.log("action payload", action.payload);
            return { ...state, loading: false, data: action.payload };
        }
        case IndividualActionTypes.FETCH_ERROR: {
            console.log('Fetch Error');
            return { ...state, loading: false, errors: action.mensaje };
        }
        case IndividualActionTypes.FETCH_CUBICA_OS: {
            console.log("cubicar ", action.payload);
            return state;
        }
        case IndividualActionTypes.FETCH_ERROR_FIND_OS: {
            console.log('Error Find OS ', action.payload);
            return {
                loading: false,
                data: {
                    osSearch: 0,
                    os: createEmptyOs()
                },
                message: undefined,
                errors: action.mensaje              
            }
        }
        default: {
            return state;
        }
    }
};
export { reducer as IndividualReducer };