import {LoginEntity, TokenBx} from '../models';
import {codigoUsuario, token, urlBase, apikey, urlToken, granttype, clientid} from "./const";
import axios from "axios";

axios.interceptors.request.use(request => {
  console.log('Starting Request', request)
  return request
})

axios.interceptors.response.use(response => {
  console.log('Response:', response)
  return response
})


export type LoggedUser = {
  error: {
    codigo: number,
    mensaje: string
  },
  usuario: {
    idSesion: number,
    usuario: string,
    nombre: string,
    codigoOficina: string,
    codigoPosta: string
  }
}

// Just a fake loginAPI
export const isValidLogin = (loginInfo: LoginEntity): Promise<boolean> =>
  new Promise((resolve) => {
    setTimeout(() => {
      // mock call
      resolve(loginInfo.login === "admin" && loginInfo.password === "test");
    }, 100000);
  });

export const loginUser = (message: LoginEntity): Promise<LoggedUser> =>
  new Promise((resolve) => {
    axios.post(urlBase + '/OperacionHubREST/login', {
      usuario: message.login,
      password: message.password
    }, {
      headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("token"),
        'Content-Type': 'application/json',
        codigoUsuario,
        token,
        apikey
      }
    }).then((value) => {
      resolve(value.data);
    });
  });

export const doToken = (message: LoginEntity): Promise<TokenBx> =>
    new Promise((resolve) => {
      const params = new URLSearchParams();
      params.append('grant_type', granttype);
      params.append('client_id', clientid);

      params.append('username', message.login);
      params.append('password', message.password);
      axios.post(urlToken, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((value) => {
        resolve(value.data);
      }).catch((response) => {
        resolve(response.status);
      });
    });
