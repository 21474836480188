import React from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NavContainer = styled.div`
  width: 100%;
  height: 50px;
   /* position: fixed; */
  background: #EBF4F8;
  margin: auto;
  -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
`;

const NavHeader = styled.div`
  width: 20%;
  float: left;
  padding: 5px;
`;

const NavHeaderLogout = styled.div`
  width: 10%;
  float: left;
  padding: 5px;
`;

interface propsFromState {

}

type AllProps = propsFromState;

const Navbar: React.FC<AllProps> = ({ children }) => {
  return (
    <div>
      <NavContainer>
        <NavHeaderLogout >
          <Link to="/logout"><FaSignOutAlt size="14" /></Link>
        </NavHeaderLogout>
        <NavHeader>
          <Link to="/individual">
            Individual
          </Link>
        </NavHeader>
        { <NavHeader>
          <Link to="/masivo">
            Masivo
          </Link>
        </NavHeader> }
        <NavHeader>
          <Link to="/pallet">
            Consolidado
          </Link>
        </NavHeader>
      </NavContainer>
      {children}
    </div>
  );
};

export default Navbar;
