import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Individual, createEmptyIndividual } from "../../store/individual/types";
import { ApplicationState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchRequest } from "../../store/individual/actions";
import { FormGroup, Col, Label } from 'reactstrap';
interface PropsFromState {
  loading: boolean;
  data: Individual;
  errors?: string;
}

interface propsFromDispatch { }

type AllProps = PropsFromState & propsFromDispatch;

const OsComponent: React.FC<AllProps> = ({
  loading,
  errors,
  data
}) => {
  useEffect(() => {
    fetchRequest();
  }, []);

  if (data === undefined) {
    data = createEmptyIndividual();
  }
  if (data.os.ordenServicio.numeroOS !== null && data.os.ordenServicio.numeroOS !== "0") {
    console.log('con datos :' +data.os.ordenServicio.numeroOS);
  return (
    <FormGroup row={true} className="py-2 m-0">
      <Col xs="5" lg="5">
        <Label size="sm">
          OS: <strong>{data.os.ordenServicio.numeroOS}</strong>
        </Label>
      </Col>
      <Col xs="7" lg="7">
        <Label size="sm">
          CLIENTE: <strong>{data.os.ordenServicio.nombreEmbarcador}</strong>
        </Label>
      </Col>
      <Col xs="5" lg="5">
        <Label size="sm">
          Piezas Emitidas: <strong>{data.os.ordenServicio.cantidadPiezas}</strong>
        </Label>
      </Col>
    </FormGroup>
  );} else {
    console.log('sin datos');
    return (
      <FormGroup row={true}>
        <Col xs="5" lg="5">
          <Label size="sm">
          </Label>
        </Col>
        <Col xs="7" lg="7">
          <Label size="sm">
          </Label>
        </Col>
      </FormGroup>
    );
  }
};

const mapStateToProps = ({ individual }: ApplicationState) => ({
  loading: individual.loading,
  errors: individual.errors,
  data: individual.data
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchRequest: () => {
      dispatch(fetchRequest());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OsComponent);
