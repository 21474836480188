export const token = process.env.REACT_APP_KONG_API_TOKEN;
export const codigoUsuario = process.env.REACT_APP_CODEUSUARIO;
export const urlBase = process.env.REACT_APP_KONG_API_URL;
export const apikey = process.env.REACT_APP_KONG_API_APIKEY;
export const urlToken = process.env.REACT_APP_URL_TOKEN || '';
export const granttype = process.env.REACT_APP_GRANT_TYPE || '';
export const clientid = process.env.REACT_APP_CLIENT_ID || '';



