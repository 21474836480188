import { OsEntity, createEmptyOs } from "../../models";

export interface Individual {
    osSearch: number;
    os: OsEntity;
}
export enum IndividualActionTypes {
    CHANGE_OS ="@@individual/CHANGE_OS",
    FETCH_REQUEST_OS = "@@individual/FETCH_REQUEST_OS",
    FETCH_CUBICA_OS = "@@individual/FETCH_CUBICA_OS",
    FETCH_SUCCESS = "@@individual/FETCH_SUCCESS",
    FETCH_ERROR = "@@individual/FETCH_ERROR",
    LOADING = "@@individual/LOADING",
    CLEAR_OS = "@@individual/CLEAR_OS",
    CLEAR_MESSAGE = "@@individual/CLEAR_MESSAGE",
    SET_OS = "@@individual/SET_OS",
    FETCH_ERROR_FIND_OS = "@@individual/FETCH_ERROR_FIND_OS"
}
export interface IndividualState {
    readonly loading: boolean;
    readonly data: Individual;
    readonly errors?: string;
    readonly message?: string;
}

export const createEmptyIndividual = (): Individual => ({
    os: createEmptyOs(),
    osSearch: 0
});

export const createEmptyIndividualState = (): IndividualState => ({
    data: createEmptyIndividual(),
    loading: false
});

export const createIndividual = (os:OsEntity,osSearch:number): Individual => ({
    os: os,
    osSearch: osSearch
});

export const createIndividualState = (individual:Individual): IndividualState => ({
    data: individual,
    loading: false
});
