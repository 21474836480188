import { IndividualActionTypes } from "./types";
import { Action, Dispatch, ActionCreator, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../index";
import { CubicacionEntity } from "../../models/cubicacion";
import { reset } from "redux-form";
import { getOs, doInsertarCubicacion, insertarCubicacionMessage } from "../../api/os";
import { OsEntity } from "../../models";

export type AppThunk = ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
>;
export const fetchRequest: AppThunk = () => {
  return (dispatch: Dispatch): Action => {
    try {
      return dispatch({
        type: IndividualActionTypes.FETCH_SUCCESS
      });
    } catch (e) {
      return dispatch({
        type: IndividualActionTypes.FETCH_ERROR
      });
    }
  };
};


export const fetchOsMock: AppThunk = (idOs: number) => {
  return (dispatch: Dispatch): Action => {
    try {
      return dispatch({
        type: IndividualActionTypes.FETCH_REQUEST_OS,
        payload: idOs
      });
    } catch (e) {
      return dispatch({
        type: IndividualActionTypes.FETCH_ERROR
      });
    }
  };
};
export const cubicar: AppThunk = (cubicacion: CubicacionEntity) => {
  console.log('Login in progress');
  return (dispatch: Dispatch): ThunkAction<Promise<void>, {}, {}, Action> => {

    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
      return new Promise<void>((resolve) => {
        console.log('Login in progress2');
        setTimeout(() => {
          setTimeout(() => {
            resolve();

          }, 1000)
        }, 3000)
        resolve();
      });
    }
  }
}

export const fetchOsAsync: AppThunk = () => {
  return (dispatch: Dispatch): Action => {
    try {
      return dispatch({
        type: IndividualActionTypes.FETCH_REQUEST_OS
      });
    } catch (e) {
      return dispatch({
        type: IndividualActionTypes.FETCH_ERROR
      });
    }
  };
};



function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getPeopleActionCreator: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Promise<Action<IndividualActionTypes>>,
  // The type for the data within the last action
  null,
  // The type of the parameter for the nested function
  null,
  // The type of the last action to be dispatched
  Action<IndividualActionTypes>
>> = () => {
  console.log('TEST');
  return async (dispatch: Dispatch) => {

    const gettingPeopleAction: Action<IndividualActionTypes.LOADING> = {
      type: IndividualActionTypes.LOADING
    }
    dispatch(gettingPeopleAction);
    await delay(5000);
    dispatch(reset('individualForm'));
    const gotPeopleAction: Action<IndividualActionTypes.CLEAR_OS> = {
      type: IndividualActionTypes.CLEAR_OS
    };
    return dispatch(gotPeopleAction);
  };
};

export interface IndividualAction extends Action<IndividualActionTypes> {
  payload?: OsEntity,
  mensaje?: string
}

export const doSubmit: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Promise<Action<IndividualActionTypes>>,
  // The type for the data within the last action
  null,
  // The type of the parameter for the nested function
  null,
  // The type of the last action to be dispatched
  Action<IndividualActionTypes>
>> = () => {
  console.log('ACTION LOGIN');
  return async (dispatch: Dispatch) => {

    const loading: IndividualAction = {
      type: IndividualActionTypes.LOADING
    }

    dispatch(loading);
    await delay(5000);
    const success: IndividualAction = {
      type: IndividualActionTypes.FETCH_SUCCESS
    }
    return dispatch(success);
  };
};

export const fetchOs: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Promise<Action<IndividualActionTypes>>,
  // The type for the data within the last action
  null,
  // The type of the parameter for the nested function
  null,
  // The type of the last action to be dispatched
  Action<IndividualActionTypes>
>> = (os: number) => {
  console.log('OBTENER OS');
  return async (dispatch: Dispatch) => {

    const loading: IndividualAction = {
      type: IndividualActionTypes.LOADING
    }
    dispatch(loading);
    var osAux = await getOs(os);

    if (osAux.error.codigo === 0) {
      const success: IndividualAction = {
        type: IndividualActionTypes.SET_OS,
        payload: osAux
      }
      return dispatch(success);
    } else {
      dispatch(reset('individualForm'));
      const success: IndividualAction = {
        type: IndividualActionTypes.FETCH_ERROR_FIND_OS,
        payload: osAux,
        mensaje:"Sin datos para OS"
      }
      return dispatch(success);
    }
  };
};

export const insertarCubicacion: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Promise<Action<IndividualActionTypes>>,
  // The type for the data within the last action
  null,
  // The type of the parameter for the nested function
  null,
  // The type of the last action to be dispatched
  Action<IndividualActionTypes>
>> = (os: insertarCubicacionMessage) => {
  console.log('INSERTAR CUBICACION');
  return async (dispatch: Dispatch) => {

    const loading: IndividualAction = {
      type: IndividualActionTypes.LOADING
    }
    dispatch(loading);
    var responseCubicar = await doInsertarCubicacion(os);

    if (responseCubicar.error.codigo === 0) {
      dispatch(reset('individualForm'));
      const success: IndividualAction = {
        type: IndividualActionTypes.FETCH_SUCCESS,
        mensaje: 'OS ' + os.listaOrdenesServicio[0].numeroOS + ' CUBICADA!'
      }
      return dispatch(success);
    } else {
      const success: IndividualAction = {
        type: IndividualActionTypes.FETCH_ERROR,
        mensaje: 'OCURRIO UN ERROR'
      }
      return dispatch(success);
    }

  };
};