import React from 'react';
import { InjectedFormProps, change } from 'redux-form';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';
import ConsolidadoPage from './consolidadoPage.component';
import { getPeopleActionCreator } from '../../store/individual/actions';

interface Props { };

export const Form: React.FC<Props & InjectedFormProps<{}, Props>> = (props: any) => {

    const nextPage = (value:any) => {
        console.log('Submit');
        console.log(value);
        props.dispatch(getPeopleActionCreator());
        //props.dispatch(cubicar(value));
        props.dispatch(change('individualForm','os','' ));
        //props.dispatch(reset('individualForm'));
    };
    return (
        <Card>
            <ConsolidadoPage onSubmit={nextPage} {...props} store={props.store}/>
        </Card>
    );
}

export default connect(null)(Form);