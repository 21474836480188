import { CubicacionEntity, OsEntity, createEmptyCubicacionEntity, createEmptyOs } from "../../models";

export interface Pallet {
    osSearch: number;
    os: OsEntity;
    cubicacion: CubicacionEntity;
}
export enum PalletActionTypes {
    CHANGE_OS ="@@pallet/CHANGE_OS",
    FETCH_REQUEST_OS = "@@pallet/FETCH_REQUEST_OS",
    FETCH_CUBICA_PALLET= "@@pallet/FETCH_CUBICA_PALLET",
    FETCH_SUCCESS = "@@pallet/FETCH_SUCCESS",
    FETCH_ERROR = "@@pallet/FETCH_ERROR",
    LOADING = "@@pallet/LOADING",
    SET_OS = "@@pallet/SET_OS",
    CHANGE_ALTO = "@@pallet/CHANGE_ALTO",
    CHANGE_LARGO = "@@pallet/CHANGE_LARGO",
    CHANGE_ANCHO = "@@pallet/CHANGE_ANCHO",
    CHANGE_PESO = "@@pallet/CHANGE_PESO",
    CHANGE_PIEZAS = "@@pallet/CHANGE_PIEZAS",
    CLEAR_MESSAGE = "@@pallet/CLEAR_MESSAGE",
    FETCH_ERROR_FIND = "@@pallet/FETCH_ERROR_FIND",
}
export interface PalletState {
    readonly loading: boolean;
    readonly data: Pallet;
    readonly errors?: string;
    readonly message?: string;
}

export const createEmptyPallet = (): Pallet => ({
    os: createEmptyOs(),
    cubicacion: createEmptyCubicacionEntity(),
    osSearch: 0
});

export const createEmptyPalletState = (): PalletState => ({
    data: createEmptyPallet(),
    loading: false
});

export const createPallet = (os:OsEntity,cubicacion: CubicacionEntity,osSearch:number): Pallet => ({
    os: os,
    cubicacion: cubicacion,
    osSearch: osSearch
});

export const createPalletState = (pallet:Pallet): PalletState => ({
    data: pallet,
    loading: false
});