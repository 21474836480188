import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchRequest } from "../../store/individual/actions";
import { Pallet, createEmptyPallet } from "../../store/pallet/types";
import ReactLoading from 'react-loading';
import CardHeader from "@material-ui/core/CardHeader";
interface PropsFromState {
  loading: boolean;
  data: Pallet;
  errors?: string;
}

interface propsFromDispatch { }

type AllProps = PropsFromState & propsFromDispatch;

const Loading: React.FC<AllProps> = ({
  loading,
  errors,
  data
}) => {
  useEffect(() => {
    fetchRequest();
  }, []);

  if (data === undefined) {
    data = createEmptyPallet();
  }
  if (loading) {
  return (
      <ReactLoading type="bubbles" color="#5cca84" height={'20%'} width={'20%'} />
  );} else {
    return (
      <CardHeader title="Consolidado"/>
    );
  }
};

const mapStateToProps = ({ pallet }: ApplicationState) => ({
  loading: pallet.loading,
  errors: pallet.errors,
  data: pallet.data
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchRequest: () => {
      dispatch(fetchRequest());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Loading);
