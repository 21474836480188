import { FormErrors } from 'redux-form';
import { ConsolidadoParams } from './consolidado.d';

const validate = (values: ConsolidadoParams): FormErrors<ConsolidadoParams> => {
    const errors: FormErrors<ConsolidadoParams> = {};

    if (!values.alto) {
      errors.alto = '* Alto!';
    }
    
    if (!values.largo) {
      errors.largo = '* Largo';
    }

    if (!values.ancho) {
      errors.ancho = '* Ancho';
    }

    if (!values.peso) {
      errors.peso = '* Peso';
    }

    if (!values.piezas) {
      errors.piezas = '* Piezas';
    }

    return errors;
};
export default validate;