import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchRequest } from "../../store/individual/actions";
import { Table, Button, Label } from 'reactstrap';
import { Masivo, createEmptyMasivo } from "../../store/masivo/types";
import { FaTrash } from "react-icons/fa";

interface PropsFromState {
  loading: boolean;
  data: Masivo;
  errors?: string;
}

interface propsFromDispatch {
  onClick: any
}

type AllProps = PropsFromState & propsFromDispatch;



const OsGridComponent: React.FC<AllProps> = ({
  loading,
  errors,
  data,
  onClick
}) => {
  useEffect(() => {
    fetchRequest();
  }, []);
  if (data === undefined) {
    data = createEmptyMasivo();
  }
  function sayHello(name: string) {
    onClick(name);
  }
  return (
    <Table responsive size="sm"
      striped >
      <thead>
        <tr>
          <th>OS {data.os.length}</th>
          <th>Cliente</th>
        </tr>
      </thead>
      <tbody>
        {data.os && data.os.map(
          (each_datarecord, recordindex) =>
            <tr key={each_datarecord.id}>
              <td><Button color="light" onClick={() => sayHello(each_datarecord.id)} value={each_datarecord.id}>
                <FaTrash />
              </Button>
                <Label size="sm">
                  {each_datarecord.os.ordenServicio.numeroOS}
                </Label></td>
              <td>{each_datarecord.os.ordenServicio.nombreEmbarcador}</td>
            </tr>
        )}
      </tbody>
    </Table>
  );
};

const mapStateToProps = ({ masivo }: ApplicationState) => ({
  loading: masivo.loading,
  errors: masivo.errors,
  data: masivo.data
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchRequest: () => {
      dispatch(fetchRequest());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OsGridComponent);
