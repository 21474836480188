export interface CubicacionEntity {
  alto: string;
  ancho: string;
  largo: string;
  peso: string;
  piezas: string;
  altoCons: string;
  anchoCons: string;
  largoCons: string;
  pesoCons: string;
}

export const createEmptyCubicacionEntity = ():CubicacionEntity => ({
  alto: '',
  ancho: '',
  largo: '',
  peso: '1',
  piezas: '',
  altoCons: '',
  anchoCons: '',
  largoCons: '',
  pesoCons: ''
});