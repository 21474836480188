
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as reducerForm } from 'redux-form';

import { History } from "history";

// import inventorySaga from "./inventory/sagas";
import { IndividualReducer } from "./individual/reducer";
import { IndividualState, createEmptyIndividualState } from "./individual/types";

// import cartSaga from "./cart/sagas";

import { PalletReducer } from "./pallet/reducer";
import { PalletState, createEmptyPalletState } from "./pallet/types";

import { RouterState } from "connected-react-router";
import { MasivoState, createEmptyMasivoState } from "./masivo/types";
import { MasivoReducer } from "./masivo/reducer";
import { LoginState, createEmptyLoginState } from "./login/types";
import { LoginReducer } from "./login/reducer";

export interface ApplicationState {
  individual: IndividualState;
  pallet: PalletState;
  masivo: MasivoState;
  login: LoginState;
  router: RouterState;
}

export const createRootReducer = (history: History) =>
  combineReducers({
    individual: IndividualReducer,
    pallet: PalletReducer,
    masivo: MasivoReducer,
    login: LoginReducer,
    form: reducerForm,
    router: connectRouter(history)
  });
  
  export const createEmptyApplicationState = (router: RouterState): ApplicationState => ({
    individual: createEmptyIndividualState(),
    masivo: createEmptyMasivoState(),
    pallet: createEmptyPalletState(),
    login: createEmptyLoginState(),
    router: router
  });