import { Reducer } from "redux";
import { PalletActionTypes, PalletState, createEmptyPallet } from "./types";
import { CubicacionEntity, createEmptyCubicacionEntity } from "../../models/cubicacion";
import { PalletAction } from "./actions";
import { createEmptyOs } from "../../models";

export enum Clientes {
  FALABELLA = 'FALABELLA',
  NATURA = 'NATURA',
  HUAWEI = 'HUAWEI',
}
function isNumber(value: string | number): boolean {
  return ((value != null) &&
    (value !== '') &&
    !isNaN(Number(value.toString())));
}
export const initialState: PalletState = {
  data: createEmptyPallet(),
  errors: undefined,
  loading: false
};
const reducer: Reducer<PalletState> = (state = initialState, action: PalletAction) => {
  switch (action.type) {
    case PalletActionTypes.LOADING: {
      console.log('Cleaar OS ');
      return {
        loading: true,
        data: state.data
      }
    }
    case PalletActionTypes.CHANGE_ALTO: {
      console.log('Change Alto ', action.payload);
      console.log('Data actual ', state.data);
      var cubicacionAux: CubicacionEntity;
      if (state.data.cubicacion === undefined) {
        cubicacionAux = createEmptyCubicacionEntity();
      } else {
        cubicacionAux = state.data.cubicacion;
      }
      if (action.valores !== undefined) {
        if (isNumber(action.valores) && isNumber(cubicacionAux.piezas)) {
          cubicacionAux.altoCons = (Number(action.valores) * Number(cubicacionAux.piezas)).toString();
        }
        if (isNumber(action.valores)) {
          cubicacionAux.alto = action.valores;
        }
      }
      return {
        loading: false,
        data: {
          osSearch: 0,
          cubicacion: cubicacionAux,
          os: state.data.os
        }
      };
    }

    case PalletActionTypes.CHANGE_LARGO: {
      console.log('Change Largo ', action.payload);
      console.log('Data actual ', state.data);
      var cubicacionAuxLargo: CubicacionEntity;
      if (state.data.cubicacion === undefined) {
        cubicacionAuxLargo = createEmptyCubicacionEntity();
      } else {
        cubicacionAuxLargo = state.data.cubicacion;
      }
      if (action.valores !== undefined) {
        if (isNumber(action.valores) && isNumber(cubicacionAuxLargo.piezas)) {
          cubicacionAuxLargo.largoCons = (Number(action.valores) * Number(cubicacionAuxLargo.piezas)).toString();
        }
      }
      if (action.valores !== undefined) {
        if (isNumber(action.valores)) {
          cubicacionAuxLargo.largo = action.valores
        }
      }

      return {
        loading: false,
        data: {
          osSearch: 0,
          cubicacion: cubicacionAuxLargo,
          os: state.data.os
        }
      };
    }

    case PalletActionTypes.CHANGE_ANCHO: {
      console.log('Change Ancho ', action.payload);
      console.log('Data actual ', state.data);
      var cubicacionAuxAncho: CubicacionEntity;
      if (state.data.cubicacion === undefined) {
        cubicacionAuxAncho = createEmptyCubicacionEntity();
      } else {
        cubicacionAuxAncho = state.data.cubicacion;
      }
      if (action.valores !== undefined) {
        if (isNumber(action.valores) && isNumber(cubicacionAuxAncho.piezas)) {
          cubicacionAuxAncho.anchoCons = (Number(action.valores) * Number(cubicacionAuxAncho.piezas)).toString();
        }
      }
      if (action.valores !== undefined) {
        if (isNumber(action.valores)) {
          cubicacionAuxAncho.ancho = action.valores
        }
      }

      return {
        loading: false,
        data: {
          osSearch: 0,
          cubicacion: cubicacionAuxAncho,
          os: state.data.os
        }
      };
    }


    case PalletActionTypes.CHANGE_PESO: {
      console.log('Change Peso ', action.payload);
      console.log('Data actual ', state.data);
      var cubicacionAuxPeso: CubicacionEntity;
      if (state.data.cubicacion === undefined) {
        cubicacionAuxPeso = createEmptyCubicacionEntity();
      } else {
        cubicacionAuxPeso = state.data.cubicacion;

      }
      if (action.valores !== undefined) {
        if (isNumber(action.valores) && isNumber(cubicacionAuxPeso.piezas)) {
          cubicacionAuxPeso.pesoCons = (Number(action.valores) * Number(cubicacionAuxPeso.piezas)).toString();
        }
      }
      if (action.valores !== undefined) {
        if (isNumber(action.valores)) {
          cubicacionAuxPeso.peso = action.valores
        }
      }

      return {
        loading: false,
        data: {
          osSearch: 0,
          cubicacion: cubicacionAuxPeso,
          os: state.data.os
        }
      };
    }


    case PalletActionTypes.CHANGE_PIEZAS: {
      console.log('Change Piezas ', action.payload);
      console.log('Data actual ', state.data);
      var cubicacionAuxPiezas: CubicacionEntity;
      if (state.data.cubicacion === undefined) {
        cubicacionAuxPiezas = createEmptyCubicacionEntity();
      } else {
        cubicacionAuxPiezas = state.data.cubicacion;
        if (action.valores !== undefined && isNumber(action.valores)) {
          if (cubicacionAuxPiezas.alto !== undefined) {
            if (cubicacionAuxPiezas.alto.length > 0) {
              if (isNumber(cubicacionAuxPiezas.alto)) {
                console.log('CHANGE ALTO');
                cubicacionAuxPiezas.altoCons = (Number(cubicacionAuxPiezas.alto) * Number(action.valores)).toString();
              }
            }
          }
          if (cubicacionAuxPiezas.largo !== undefined) {
            if (cubicacionAuxPiezas.largo.length > 0) {
              if (isNumber(cubicacionAuxPiezas.largo)) {
                cubicacionAuxPiezas.largoCons = (Number(cubicacionAuxPiezas.largo) * Number(action.valores)).toString();
              }
            }
          }
          if (cubicacionAuxPiezas.ancho !== undefined) {
            if (cubicacionAuxPiezas.ancho.length > 0) {
              if (isNumber(cubicacionAuxPiezas.ancho)) {
                cubicacionAuxPiezas.anchoCons = (Number(cubicacionAuxPiezas.ancho) * Number(action.valores)).toString();
              }
            }
          }
          if (cubicacionAuxPiezas.peso !== undefined) {
            if (cubicacionAuxPiezas.peso.length > 0) {
              if (isNumber(cubicacionAuxPiezas.peso)) {
                cubicacionAuxPiezas.pesoCons = (Number(cubicacionAuxPiezas.peso) * Number(action.valores)).toString();
              }
            }
          }
        }
      }
      if (action.valores !== undefined && isNumber(action.valores)) {
        cubicacionAuxPiezas.piezas = action.valores;
      }

      return {
        loading: false,
        data: {
          osSearch: 0,
          cubicacion: cubicacionAuxPiezas,
          os: state.data.os
        }
      };
    }

    case PalletActionTypes.CHANGE_OS: {
      console.log('Change OS Pallet ', action.payload);
      return {
        loading: false,
        data: {
          osSearch: 0,
          cubicacion: state.data.cubicacion,
          os: state.data.os
        }
      };
    }

    case PalletActionTypes.FETCH_REQUEST_OS: {
      console.log('Cargando OS ', state.data.osSearch);
      if (state.data.osSearch > 0) {
        console.log('Cargando OS');
        return {
          loading: false,
          data: {
            osSearch: 0,
            cubicacion: state.data.cubicacion,
            os: state.data.os
          }
        };

      } else {
        console.log('VACIO');
        return state;
      }
    }

    case PalletActionTypes.SET_OS: {
      console.log('SET OS ', action.payload);
      if (action.payload !== undefined) {
        return {
          loading: false,
          data: {
            osSearch: 0,
            cubicacion: createEmptyCubicacionEntity(),
            os: action.payload
          }
        };
      } else {
        return state;
      }

    }

    case PalletActionTypes.FETCH_SUCCESS: {
      console.log("action payload", action.mensaje);
      var auxState: PalletState = {
        data: {
          cubicacion: createEmptyCubicacionEntity(),
          os: createEmptyOs(),
          osSearch: 0
        },
        message: action.mensaje,
        loading: false
      }
      return auxState;
    }
    case PalletActionTypes.FETCH_ERROR: {
      console.log('FETCH_ERROR', action.mensaje);
      return { ...state, loading: false, errors: action.mensaje };
    }

    case PalletActionTypes.CLEAR_MESSAGE: {
      console.log('Clear Message');
      return {
        loading: false,
        data: state.data,
        message: undefined
      }
    }
    case PalletActionTypes.FETCH_ERROR_FIND: {
      console.log("FETCH_ERROR_FIND", action.mensaje);
      var auxStateError: PalletState = {
        data: {
          cubicacion: createEmptyCubicacionEntity(),
          os: createEmptyOs(),
          osSearch: 0
        },
        message: undefined,
        errors: action.mensaje,
        loading: false
      }
      return auxStateError;
    }
    default: {
      return state;
    }
  }
  
};
export { reducer as PalletReducer };