import * as React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import createStyles from "@material-ui/styles/createStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { LoginEntity, createEmptyLogin } from "../../models";
import { connect } from "react-redux";

interface Props {
}

// https://material-ui.com/styles/api/#makestyles-styles-options-hook
const useFormStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  })
);

export const LoginComponent: React.FC<Props> = (props: any) => {
  const { onLogin } = props;
  const [loginInfo, setLoginInfo] = React.useState<LoginEntity>(
    createEmptyLogin()
  );
  const classes = useFormStyles();
  const onTexFieldChange = (fieldId: string) => (e: any) => {
    setLoginInfo({
      ...loginInfo,
      [fieldId]: e.target.value,
    });
  };

  return (
    <form>
      <div className={classes.formContainer}>
        <TextField
          label="Usuario"
          margin="normal"
          value={loginInfo.login}
          onChange={onTexFieldChange("login")}
        />
        <TextField
          label="Password"
          type="password"
          margin="normal"
          value={loginInfo.password}
          onChange={onTexFieldChange("password")}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => onLogin(loginInfo)}>
          Ingresar
      </Button>
      </div>
    </form>
  );
};


export default connect(null)(LoginComponent);