import React from 'react';
import { FaSave } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import { name } from '../../lib/normalize';
import { deleteOs, fetchOs } from '../../store/masivo/actions';
import '../../styles/reduxformjest.css';
import validate from '../individual/validate';
import OsGridComponent from '../os/osGrid.component';
import ReduxFormInput from '../ReduxFormInput';
import ReduxOsFinder from '../ReduxOsFinder';
import Loading from './loading';

interface Props {
};

export const MasivoPage: React.FC<Props & InjectedFormProps<{}, Props>> = (props: any) => {
  const { handleSubmit } = props;
  const onClick = () => {
    console.log('masivoForm');
    props.dispatch(fetchOs(props.store.getState().form.masivoForm.values.os));
    props.dispatch(change('masivoForm', 'os', ''));
  }

  const onClickRemove = (id: number) => {
    console.log('onClick Remove ', id);
    props.dispatch(deleteOs(id));
  }
  const handleKey = (event: any): boolean => {
    console.log(event);
    if (event.charCode === 0 && event.keyCode === 13) {
      if (event.target.value.length === 10 || event.target.value.length === 28) {
        console.log('Llamar a fetchOs');
        props.dispatch(fetchOs(props.store.getState().form.masivoForm.values.os));
        props.dispatch(change('masivoForm', 'os', ''));
        return false;
      }
    }
    return true;
  }
  const onKeyPress = (event:any) => {
    console.log(event);
    if (event.key === 'Enter') {
      event.preventDefault(); //<===== This stops the form from being submitted
    } 
  }
  props.dispatch(change('masivoForm', 'peso', '1.0'));
  props.dispatch(change('masivoForm', 'piezas', '1'));
  return (
    <>
      <Loading />
      <Card className="card-border">
        <CardBody>
          <form onSubmit={handleSubmit} noValidate={true} onKeyPress={onKeyPress}>
            <Field
              name="alto"
              type="number"
              component={ReduxFormInput}
              label="Alto (cm.)"
              placeHolder="0"
              normalize={name}
            />
            <Field
              name="ancho"
              type="number"
              component={ReduxFormInput}
              label="Ancho (cm.)"
              placeHolder="0"
              normalize={name}
            />

            <Field
              name="largo"
              type="number"
              component={ReduxFormInput}
              label="Largo (cm.)"
              placeHolder="0"
              normalize={name}
            />

            <Field
              name="peso"
              type="number"
              component={ReduxFormInput}
              label="Peso (kg.)"
              placeHolder="0"
              normalize={name}
              //disabled={true}
            />
            <Field
              name="piezas"
              type="number"
              component={ReduxFormInput}
              label="Piezas (kg.)"
              placeHolder="0"
              normalize={name}
              disabled={true}
            />
            <Field
              name="os"
              type="number"
              component={ReduxOsFinder}
              label="OS"
              placeHolder="1234567"
              onClick={onClick}
              onKeyPress={handleKey}
            />

            <FormGroup row={true}>
              <Col xs="12" lg="12">
                <OsGridComponent onClick={onClickRemove} />
              </Col>
            </FormGroup>
            <Row className="p-3">
              <Button
                block
                size="md"
                color="success"
                type="submit"
                style={{ marginRight: '10px' }}
              >
                Grabar
                &nbsp;
              <FaSave className="button-padding" size={16} />
              </Button>
            </Row>
          </form>
        </CardBody>
      </Card>
    </>
  );
}

const form = reduxForm<{}, Props>({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  form: 'masivoForm',
  validate,
})(MasivoPage);

export default connect(null)(form);
