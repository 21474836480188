import { Reducer } from "redux";
import { MasivoActionTypes, MasivoState, createEmptyMasivo, OsEntityRubber } from "./types";
import { Guid } from "guid-typescript";

export enum Clientes {
  FALABELLA = 'FALABELLA',
  NATURA = 'NATURA',
  HUAWEI = 'HUAWEI',
}

export const initialState: MasivoState = {
  data: createEmptyMasivo(),
  errors: undefined,
  loading: false
};
const reducer: Reducer<MasivoState> = (state = initialState, action) => {
  switch (action.type) {
    case MasivoActionTypes.LOADING: {
      console.log('Cleaar OS ');
      return {
        loading: true,
        data: state.data
      }
    }
    case MasivoActionTypes.CHANGE_OS: {
      console.log('Change OS ', action.payload);
      return {
        data: {
          osSearch: action.payload,
          os: state.data.os
        },
        loading: false
      };
    }
    case MasivoActionTypes.FETCH_REQUEST_OS: {
      console.log('Masivo - Request OS ');
      var auxOs: OsEntityRubber[] = state.data.os;
      return {
        data: {
          osSearch: state.data.osSearch,
          os: auxOs
        }
        , loading: false
      };
    }
    case MasivoActionTypes.FETCH_SUCCESS: {
      console.log("action FETCH_SUCCESS", action.mensaje);
      var auxOs3: OsEntityRubber[] = [];
      return {
        loading: false,
        data: {
          osSearch: 0,
          os: auxOs3
        },
        message: state.data.os.length + ' OS CUBICADAS!'
      }
    }
    case MasivoActionTypes.CLEAR_MESSAGE: {
      console.log('Clear Message');
      return {
        loading: false,
        data: state.data,
        message: undefined
      }
    }
    case MasivoActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.mensaje };
    }
    case MasivoActionTypes.DELETE_OS: {
      console.log('Delete id ', action.payload);
      return {
        data: {
          osSearch: state.data.osSearch,
          os: state.data.os.filter((os) => { if (os.id === action.payload.toString()) { return false; } return true; })
        }
        , loading: false
      };
    }
    case MasivoActionTypes.SET_OS: {
      console.log('SET OS ', action.payload);
      if (state.data !== undefined) {
        console.log('CON DATOS');
        var auxOsSet: OsEntityRubber[] = state.data.os;
        const nuevo: OsEntityRubber = {
          id: Guid.create().toString(),
          os: action.payload
        }
        auxOsSet.push(nuevo);
        return {
          loading: false,
          data: {
            osSearch: '',
            os: auxOsSet
          }
        }
      } else {
        console.log('NUEVO');
        var auxOsSet2: OsEntityRubber[] = [];
        const nuevo: OsEntityRubber = {
          id: Guid.create().toString(),
          os: action.payload
        }
        auxOsSet2.push(nuevo);
        return {
          loading: false,
          data: {
            osSearch: '',
            os: auxOsSet2
          }
        }
      }

    }
    case MasivoActionTypes.CLEAR_DATA: {
      console.log("action CLEAR_DATA", action.payload);
      var data1: OsEntityRubber[] = [];
      return {
        loading: false,
        data: {
          osSearch: 0,
          os: data1
        },
        message: undefined
      }
    }
    default: {
      return state;
    }
  }
};
export { reducer as MasivoReducer };