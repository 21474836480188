import { MasivoActionTypes } from "./types";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ApplicationState } from "../index";
import { OsEntity } from "../../models";
import { getOs, doInsertarCubicacion, insertarCubicacionMessage } from "../../api/os";
import { reset } from "redux-form";

export type AppThunk = ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
>;

export interface MasivoAction extends Action<MasivoActionTypes> {
  payload? : OsEntity,
  mensaje?: string
}

export const fetchRequest: AppThunk = () => {
  return (dispatch: Dispatch): Action => {
    try {
      return dispatch({
        type: MasivoActionTypes.FETCH_SUCCESS,
        payload: undefined
      });
    } catch (e) {
      return dispatch({
        type: MasivoActionTypes.FETCH_ERROR
      });
    }
  };
};

export const changeOs: AppThunk = (os: number) => {
  return (dispatch: Dispatch): Action => {
    try {
      return dispatch({
        type: MasivoActionTypes.CHANGE_OS,
        payload: os
      });
    } catch (e) {
      return dispatch({
        type: MasivoActionTypes.FETCH_ERROR
      });
    }
  };
};

export const fetchOsMock: AppThunk = () => {
  return (dispatch: Dispatch): Action => {
    try {
      return dispatch({
        type: MasivoActionTypes.FETCH_REQUEST_OS
      });
    } catch (e) {
      return dispatch({
        type: MasivoActionTypes.FETCH_ERROR
      });
    }
  };
};

export const deleteOs: AppThunk = (os: number) => {
  return (dispatch: Dispatch): Action => {
    try {
      return dispatch({
        type: MasivoActionTypes.DELETE_OS,
        payload: os
      });
    } catch (e) {
      return dispatch({
        type: MasivoActionTypes.FETCH_ERROR
      });
    }
  };
};

export const fetchOs: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Promise<Action<MasivoActionTypes>>,
  // The type for the data within the last action
  null,
  // The type of the parameter for the nested function
  null,
  // The type of the last action to be dispatched
  Action<MasivoActionTypes>
>> = (os:number) => {
  console.log('OBTENER OS');
  return async (dispatch: Dispatch) => {
    
    const loading: MasivoAction = {
      type: MasivoActionTypes.LOADING
    }
    dispatch(loading);
    var osAux = await getOs(os);

    if (osAux.error.codigo === 0) {
      const success: MasivoAction = {
        type: MasivoActionTypes.SET_OS,
        payload: osAux
      }
      return dispatch(success);
    } else {
      const error: MasivoAction = {
        type: MasivoActionTypes.FETCH_ERROR,
        payload: osAux,
        mensaje: "Sin datos para OS"
      }
      return dispatch(error);
    }
  };
};

export interface MasivoAction extends Action<MasivoActionTypes> {
  payload?: OsEntity,
  mensaje?: string
}

export const insertarCubicacion: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Promise<Action<MasivoActionTypes>>,
  // The type for the data within the last action
  null,
  // The type of the parameter for the nested function
  null,
  // The type of the last action to be dispatched
  Action<MasivoActionTypes>
>> = (os: insertarCubicacionMessage) => {
  console.log('INSERTAR CUBICACION');
  return async (dispatch: Dispatch) => {

    const loading: MasivoAction = {
      type: MasivoActionTypes.LOADING
    }
    dispatch(loading);
    var responseCubicar = await doInsertarCubicacion(os);

    if (responseCubicar.error.codigo === 0) {
      dispatch(reset('masivoForm'));
      const success: MasivoAction = {
        type: MasivoActionTypes.FETCH_SUCCESS,
        mensaje: os.listaOrdenesServicio.length + ' OS CUBICADAS!'
      }
      return dispatch(success);
    } else {
      const success: MasivoAction = {
        type: MasivoActionTypes.FETCH_ERROR,
        mensaje: 'OCURRIO UN ERROR'
      }
      return dispatch(success);
    }

  };
};