import React from 'react';
import { FaSave } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import '../../App.css';
import { ApplicationState } from '../../store';
import { fetchOs } from '../../store/pallet/actions';
import { Pallet, PalletActionTypes } from '../../store/pallet/types';
import ReduxFormInputConsolidado from '../ReduxFormInput/ReduxFormInputConsolidado';
import ReduxOsFinder from '../ReduxOsFinder';
import OsComponentPallet from '../os/os.componentPallet';
import Loading from './loading';
import validate from './validate';

interface Props { 
  loading: boolean;
  data: Pallet;
  errors?: string;
};

export const ConsolidadoPage: React.FC<Props & InjectedFormProps<{}, Props>> = (props: any,data:Pallet) => {
  const { handleSubmit } = props;
  const onClick = () => {
    props.dispatch(fetchOs(props.store.getState().form.consolidadoForm.values.os));
    props.dispatch(change('consolidadoForm', 'os', ''));
  }

  const handleKey = (event: any) => {
    if (event.charCode === 0 && event.keyCode === 13) {
      if (event.target.value.length === 10 || event.target.value.length === 28) {
        props.dispatch(fetchOs(props.store.getState().form.consolidadoForm.values.os));
        props.dispatch(change('consolidadoForm', 'os', ''));
      }
    }
  }
  const onKeyPress = (event:any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); //<===== This stops the form from being submitted
    } 
  }
  const handleChangeForm = (event: any) => {
    if (event.target.name === 'alto') {
      console.log('SEND alto');
      props.store.dispatch({
        type: PalletActionTypes.CHANGE_ALTO,
        valores: event.target.value
      });
    }
    if (event.target.name === 'largo') {
      console.log('SEND largo');
      props.store.dispatch({
        type: PalletActionTypes.CHANGE_LARGO,
        valores: event.target.value
      });
    }
    if (event.target.name === 'ancho') {
      console.log('SEND ancho');
      props.store.dispatch({
        type: PalletActionTypes.CHANGE_ANCHO,
        valores: event.target.value
      });
    }
    if (event.target.name === 'peso') {
      console.log('SEND peso');
      props.store.dispatch({
        type: PalletActionTypes.CHANGE_PESO,
        valores: event.target.value
      });
    }
    if (event.target.name === 'piezas') {
      console.log('SEND piezas');
      props.store.dispatch({
        type: PalletActionTypes.CHANGE_PIEZAS,
        valores: event.target.value
      });
    }
  }

  props.dispatch(change('consolidadoForm', 'peso', '1.0'));
  return (
    <>
      <Loading />
      <form onSubmit={handleSubmit} noValidate={false} onKeyPress={onKeyPress}>
        <Col sm="12">
          <Card className="card-border">
            <CardBody>
              <FormGroup row={true} >
                <Col xs="12" lg="12">
                  <Field
                    name="os"
                    type="number"
                    component={ReduxOsFinder}
                    label="OS"
                    placeHolder="1234567"
                    onClick={onClick}
                    onChange={handleChangeForm}
                    onKeyPress={handleKey}
                    onKeyDown={handleKey}
                  />
                </Col>
              </FormGroup>
              <OsComponentPallet />
              <FormGroup row={true}>
                <Col xs="12" lg="12">
                  <Field
                    name="piezas"
                    type="text"
                    component={ReduxFormInputConsolidado}
                    label="Piezas"
                    placeHolder="0"
                    onChange={handleChangeForm}
                  />
                </Col>
              </FormGroup>
              <Row>
                <Col xs="8" lg="8">
                  <label>Consolidado</label>
                </Col>
              </Row>
              <Row>
                <Col xs="10" lg="10">
                  <Field
                    name="alto"
                    type="text"
                    component={ReduxFormInputConsolidado}
                    label="Alto"
                    placeHolder="0"
                    onChange={handleChangeForm}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="10" lg="10">
                  <Field
                    name="ancho"
                    type="text"
                    component={ReduxFormInputConsolidado}
                    label="Ancho"
                    placeHolder="0"
                    onChange={handleChangeForm}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="10" lg="10">
                  <Field
                    name="largo"
                    type="text"
                    component={ReduxFormInputConsolidado}
                    label="Largo"
                    placeHolder="0"
                    onChange={handleChangeForm}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="10" lg="10">
                  <Field
                    name="peso"
                    type="text"
                    component={ReduxFormInputConsolidado}
                    label="Peso"
                    placeHolder="0.0"
                    onChange={handleChangeForm}
                    //disabled={true}
                  />
                </Col>
              </Row>
              <Button
                block
                color="success"
                type="submit"
                style={{ marginRight: '10px' }}
              >
                Grabar
                &nbsp;
              <FaSave className="button-padding" size={16} />
              </Button>
            </CardBody>

          </Card>
        </Col>
      </form>
    </>
  );
}

const form = reduxForm<{}, Props>({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  form: 'consolidadoForm',
  validate,
})(ConsolidadoPage);

const mapStateToProps = ({ pallet }: ApplicationState) => ({
  loading: pallet.loading,
  errors: pallet.errors,
  data: pallet.data
});
export default connect(mapStateToProps, null)(form);
