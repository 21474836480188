import React from 'react';
import { InjectedFormProps } from 'redux-form';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';

import IndividualPage from './individualPage.component';
import { getPeopleActionCreator } from '../../store/individual/actions';

interface Props { };

export const Form: React.FC<Props & InjectedFormProps<{}, Props>> = (props: any) => {
    console.log("PROPS", props);
    const nextPage = () => {
        console.log('Submit');
        props.store.dispatch(getPeopleActionCreator()); 
    };
    return (
        <Card> 
            <IndividualPage onSubmit={nextPage} {...props} store={props.store} />
        </Card>
    );
}

export default connect(null)(Form);