import React from 'react';
import { FaSave } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, FormGroup } from 'reactstrap';
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form';
import '../../App.css';
import { fetchOs } from '../../store/individual/actions';
import ReduxFormInput from '../ReduxFormInput';
import ReduxOsFinder from '../ReduxOsFinder';
import OsComponent from '../os/os.component';
import Loading from './loading';
import validate from './validate';
interface Props { }

export const IndividualPage: React.FC<Props & InjectedFormProps<{}, Props>> = (props: any) => {
  const { handleSubmit, onClick } = props;  
  // const onClick = () => {
  //   console.log('onClick');
  //   props.dispatch(fetchOs(props.store.getState().form.individualForm.values.os));
  //   props.dispatch(change('individualForm', 'os', ''));
  // }
  const handleKey = (event: any) => {
    if (event.charCode === 0 && event.keyCode === 13) {
      if (event.target.value.length === 10 || event.target.value.length === 28) {
        console.log('Llamar a fetchOs');
        props.dispatch(fetchOs(props.store.getState().form.individualForm.values.os));
        props.dispatch(change('individualForm', 'os', ''));        
      }
    }
  }
  const onKeyPress = (event: any) => {
    console.log(event);
    if (event.key === 'Enter') {
      event.preventDefault(); //<===== This stops the form from being submitted
    }
  }
  props.dispatch(change('individualForm', 'peso', '1.0'));
  props.dispatch(change('individualForm', 'piezas', '1'));

  if (props.barcode ) {
    if (!props.store.getState().form.individualForm.values.os) {
      props.dispatch(change('individualForm', 'os', props.barcode));
      onClick();
    }
  }

  return (
    <>
      <Loading />
      <form onSubmit={handleSubmit} noValidate={false} onKeyPress={onKeyPress}>
        <div className="form-row justify-content-center">
          <Col sm="12">
            <Card className="card-border">
              <CardBody>
                <FormGroup row={true} >
                  <Col xs="12" lg="12">
                    <Field
                      name="os"
                      type="number"
                      component={ReduxOsFinder}
                      label="OS"
                      placeHolder="1234567"
                      onClick={onClick}
                      onKeyPress={handleKey}
                      onKeyDown={handleKey}
                    />
                  </Col>
                </FormGroup>
                <OsComponent />
                <Field
                  name="alto"
                  type="number"
                  component={ReduxFormInput}
                  label="Alto (cm.)"
                  placeHolder="0"
                />

                <Field
                  name="ancho"
                  type="number"
                  component={ReduxFormInput}
                  label="Ancho (cm.)"
                  placeHolder="0"
                />

              <Field
                name="largo"
                type="number"
                component={ReduxFormInput}
                label="Largo (cm.)"
                placeHolder="0"
              />


                <Field
                  name="peso"
                  type="text"
                  component={ReduxFormInput}
                  label="Peso (kg.)"
                  placeHolder="0"
                  //disabled={true}
                />
                <Field
                  name="piezas"
                  type="text"
                  component={ReduxFormInput}
                  label="Piezas"
                  placeHolder="0.0"
                  disabled={true}
                />
                <Button
                  block
                  color="success"
                  type="submit"
                  style={{ marginRight: '10px' }}
                >
                  Grabar
                  &nbsp;
                  <FaSave className="button-padding" size={16} />
                </Button>
              </CardBody>


            </Card>
          </Col>
        </div>
      </form>
    </>
  );
}

const form = reduxForm<{}, Props>({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  form: 'individualForm',
  validate,
})(IndividualPage);

export default connect(null)(form);
