import { CubicacionEntity, OsEntity } from "../../models";

export interface OsEntityRubber {
    id: string,
    os: OsEntity
}

export interface Masivo {
    osSearch: number;
    os: OsEntityRubber[];
}

export enum MasivoActionTypes {
    CHANGE_OS ="@@masivo/CHANGE_OS",
    DELETE_OS ="@@masivo/DELETE_OS",
    FETCH_REQUEST_OS = "@@masivo/FETCH_REQUEST_OS",
    FETCH_CUBICA_MASIVO = "@@masivo/FETCH_CUBICA_MASIVO",
    FETCH_SUCCESS = "@@masivo/FETCH_SUCCESS",
    FETCH_ERROR = "@@masivo/FETCH_ERROR",
    SET_OS = "@@masivo/SET_OS",
    LOADING = "@@masivo/LOADING",
    CLEAR_MESSAGE = "@@masivo/CLEAR_MESSAGE",
    CLEAR_DATA = "@@masivo/CLEAR_DATA"
}
export interface MasivoState {
    readonly loading: boolean;
    readonly data: Masivo;
    readonly errors?: string;
    readonly message?: string;
}

export const createEmptyMasivo = (): Masivo => ({
    os: [],
    osSearch: 0
});

export const createEmptyMasivoState = (): MasivoState => ({
    data: createEmptyMasivo(),
    loading: false
});

export const createMasivo = (os:OsEntityRubber[],cubicacion: CubicacionEntity,osSearch:number): Masivo => ({
    os: os,
    osSearch: osSearch
});

export const createIndividualState = (individual:Masivo): MasivoState => ({
    data: individual,
    loading: false
});