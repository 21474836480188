import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const NavContainer = styled.div`
  width: 100%;
  height: 50px;
   /* position: fixed; */
  background: #EBF4F8;
  margin: auto;
  -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
`;

const NavHeader = styled.div`
  width: 20%;
  float: left;
  padding: 10px;
`;

interface propsFromState {

}

type AllProps = propsFromState;

const NavbarLogin: React.FC<AllProps> = ({ children }) => {
  return (
    <div>
      <NavContainer>
        <NavHeader>
          <Link to="/login">Login</Link>
        </NavHeader>
      </NavContainer>
      {children}
    </div>
  );
};

export default NavbarLogin;
