import { OsEntity, createEmptyOs } from "../models";
import axios from "axios";
import {codigoUsuario,token,urlBase,apikey} from "./const";

axios.interceptors.request.use(request => {
    console.log('Starting Request', request)
    return request
  })
  
  axios.interceptors.response.use(response => {
    console.log('Response:', response)
    return response
  })

type getOsMessage = {
    idOs: number,
    token: string,
    codigoUsuario: string
}
export type insertarCubicacionMessage = {
    codigoUsuario: string,
    largo: string,
    ancho: string,
    alto: string,
    peso: string,
    origen: string,
    codigoOficina: string,
    listaOrdenesServicio: [
        {
            numeroOS: string,
            especieValorada: string,
            piezas: string
        }
    ]
}

export type ResponseCubicar = {
    error: {
      codigo: number,
      mensaje: string
    }
  }

export const getOsMock = (message: getOsMessage): Promise<OsEntity> =>
    new Promise((resolve) => {
        setTimeout(() => {
            // mock call
            let osMock: OsEntity = createEmptyOs();
            osMock.ordenServicio.numeroOS = message.idOs.toString();
            osMock.ordenServicio.nombreEmbarcador = "FALABELLA";
            resolve(osMock);
        }, 500);
    });
export const doInsertarCubicacion = (message: insertarCubicacionMessage): Promise<ResponseCubicar> =>
    new Promise((resolve) => {
        axios.post(urlBase + '/CubicacionServices/BXCB001_CUBICACION_REST/insertarCubicacion',  message , {
            headers: {
                'Content-Type': 'application/json',
                codigoUsuario,
                token,
                apikey

            }
        }).then((value) => {
            console.log('VALUE ', value);
            resolve(value.data);
        });
    });

export const getOs = (id: number): Promise<OsEntity> =>
    new Promise((resolve) => {
        axios.post(urlBase + '/CubicacionServices/BXCB001_CUBICACION_REST/obtenerOS', { numeroOS: id }, {
            headers: {
                'Content-Type': 'application/json',
                codigoUsuario,
                token,
                apikey
            }
        }).then((value) => {
            resolve(value.data);
        });
    });
