import React from 'react';
import { connect } from 'react-redux';
import Form from './Form';
import { Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Store } from 'redux';
import { ApplicationState } from '../../store';
import { push } from 'connected-react-router';
import { insertarCubicacionMessage } from '../../api/os';
import { insertarCubicacion, PalletAction} from './../../store/pallet/actions';
import { PalletActionTypes } from '../../store/pallet/types';
import { reset } from "redux-form";

interface MainProps {
  store: Store<ApplicationState>;
}

export const Consolidado = (props: any) => {
  
  const handleSubmit = (values: any) => {
    console.log('handleSubmit', values);
    var ok:boolean = false;
    if (props.store.getState().pallet.data) {
      if (props.store.getState().pallet.data.os !== undefined) {
        if (props.store.getState().pallet.data.os.ordenServicio !== undefined) {
          if (props.store.getState().pallet.data.os.ordenServicio.numeroOS !== "0") {
            ok = true;
            const modal: insertarCubicacionMessage = {
              alto: values.alto, // props.store.getState().pallet.data.cubicacion.altoCons,
              ancho: values.ancho, //props.store.getState().pallet.data.cubicacion.anchoCons,
              codigoOficina: props.store.getState().login.user.usuario.codigoOficina.toString(),
              codigoUsuario: props.store.getState().login.user.usuario.usuario.toString(),
              largo: values.largo, //props.store.getState().pallet.data.cubicacion.largoCons,
              listaOrdenesServicio: [
                {
                  numeroOS: props.store.getState().pallet.data.os.ordenServicio.numeroOS.toString(),
                  especieValorada: props.store.getState().pallet.data.os.ordenServicio.especieValorada.toString(),
                  piezas: values.piezas.toString()
                }
              ],
              origen: "2",
              peso: values.peso //props.store.getState().pallet.data.cubicacion.pesoCons

            };
            console.log('MENSAJE: ',modal);
            props.store.dispatch(insertarCubicacion(modal));
          }
        }
      }
    }

    if (!ok) {
      console.log('NO SE HA SELECCIONADO OS');
      const success: PalletAction = {
        type: PalletActionTypes.FETCH_ERROR,
        mensaje: 'NO SE HA SELECCIONADO OS'
      }
      props.store.dispatch(success);
    }
  }

  props.store.subscribe(() => {
    if (props.store.getState().pallet.message !== undefined || props.store.getState().pallet.errors !== undefined) {
      if (props.store.getState().pallet.message !== undefined) {
        toast.info(props.store.getState().pallet.message);
      }
      if (props.store.getState().pallet.errors !== undefined) {
        toast.error(props.store.getState().pallet.errors);
      }
      const success: PalletAction = {
        type: PalletActionTypes.CLEAR_MESSAGE
      }
      props.store.dispatch(success);

    }
  });

  console.log("LOGGED IN", props.store.getState().login.loggedin);
  props.store.dispatch(reset('consolidadoForm'));
  props.store.dispatch({
    type: PalletActionTypes.FETCH_SUCCESS,
    mensaje: undefined
  });

  if (!props.store.getState().login.loggedin) {
    props.store.dispatch(push('/login'));
  }
  return (
    <>
    <div>&nbsp;<ToastContainer /></div>
    <Col sm="11">
      <Form onSubmit={handleSubmit}  {...props} />
    </Col>
    </>
  );
}

export default connect(null)(Consolidado);