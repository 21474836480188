import React from "react";
import { Route, Switch } from "react-router-dom";
import { LoginContainer } from "./components/login/login.container";

import { connect } from "react-redux";
import { Store } from "redux";
import Navbar from "./components/Navbar";
import NavbarLogin from "./components/NavbarLogin";
import { Consolidado } from "./components/consolidado/consolidado";
import { Individual } from "./components/individual/individual";
import Masivo from "./components/masivo/masivo";
import { ApplicationState } from "./store";

interface MainProps {
  store: Store<ApplicationState>;
}

const Routes: React.SFC<MainProps> = ({ store }, props: any) => {
  // console.log("STORE 2" , store.getState().individual);
  return (
    <div>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              console.log(store.getState().login);
              return (
                <NavbarLogin >
                  <LoginContainer store={store} logout={false} />
                </NavbarLogin>
              );
            }}
          />
          <Route
            exact
            path="/logout"
            render={() => {
              console.log(store.getState().login);
              return (
                <NavbarLogin >
                  <LoginContainer store={store} logout={true} />
                </NavbarLogin>
              );
            }}
          />
          <Route
            exact
            path="/login"
            render={() => (
              <NavbarLogin >
                <LoginContainer store={store} />
              </NavbarLogin>
            )}
          />
          <Route
            path="/individual/:barcode?"
            render={() => {
              console.log('LOGEANDO?', store.getState().login?.loggedin);
              return (
                <Navbar>
                  <Individual store={store} />
                </Navbar>
              )
            }}
          />
          <Route
          path="/masivo"
          render={() => (
            <Navbar>
              <Masivo store={store} />
            </Navbar>
          )}
          />
          <Route
            exact
            path="/pallet"
            render={() => (
              <Navbar>
                <Consolidado store={store} />
              </Navbar>
            )}
          />
        </Switch>
    </div>
  );
}

export default connect(null)(Routes);
