export interface OsEntity {
  error: {
    codigo: number,
    mensaje: string
  },
  ordenServicio: {
    nombreEmbarcador: string,
    cantidadPiezas: string,
    codigoPieza: string,
    especieValorada: string,
    numeroOS: string
  }
}

export const createEmptyOs = (): OsEntity => ({
  error: {
    codigo : 0,
    mensaje : "OK"
  },
  ordenServicio : {
    nombreEmbarcador :  "",
    cantidadPiezas: "",
    codigoPieza: "",
    especieValorada: "",
    numeroOS: "0"
  }
});

export const createOs = (id:number,origen:string): OsEntity => ({
  error: {
    codigo : 0,
    mensaje : "OK"
  },
  ordenServicio : {
    nombreEmbarcador :  origen,
    cantidadPiezas: '',
    codigoPieza: '',
    especieValorada: '',
    numeroOS: id.toString()
  }
});

export interface TokenBx {
  access_token: string,
  expires_in: number,
  refresh_expires_in: number,
  refresh_token: string,
  token_type: string,
  'not-before-policy': number,
  session_state: string,
  scope: string
}
