import React from 'react';
import { Input, Label, Col, Button } from 'reactstrap';
import { FaSearch } from 'react-icons/fa';

const ReduxOsFinder: React.FC = (field: any) => {
  return <div className="row p-3 Boxsearch">
    <Col xs="2" lg="2">
      <Label size="sm">{field.label}</Label>
    </Col>
    <Col xs="7" lg="7">
      <Input
        {...field.input}
        type={field.type}
        placeholder={field.placeHolder}
        max={field.maxDate}
        min={field.minDate}
        step={field.step}
        disabled={field.disabled}
        onKeyDown={field.onKeyPress}
        size="sm"
        autoFocus={true}
      />
    </Col>
    <Col xs="3" lg="3">
      <Button onClick={field.onClick} color="primary">
      <FaSearch className="button-padding" size={14} />
      </Button>
    </Col>
  </div>;
}
export default ReduxOsFinder;
