import React from 'react';
import { connect } from 'react-redux';
import Form from './Form';
import { Col } from 'reactstrap';
import { Store } from 'redux';
import { ApplicationState } from '../../store';
import { push } from 'connected-react-router';
import { insertarCubicacionMessage } from '../../api/os';
import { insertarCubicacion, MasivoAction } from '../../store/masivo/actions';
import { ToastContainer, toast } from 'react-toastify';
import { MasivoActionTypes } from '../../store/masivo/types';
import { reset } from "redux-form";

interface MainProps {
  store: Store<ApplicationState>;
}
export const Masivo: React.FC<MainProps> = (props: any) => {

  const handleSubmit = (values: any) => {
    console.log('values', values);
    var ok:boolean = false;
    if (props.store.getState().masivo.data) {
      if (props.store.getState().masivo.data.os !== undefined) {
        if (props.store.getState().masivo.data.os.length > 0) {
          console.log('Do submit');
          ok = true;
          var ordenes: [{ numeroOS: string; especieValorada: string; piezas: string; }] = props.store.getState().masivo.data.os.map((value: any) => {
            console.log('value map:', value);
            return {
              numeroOS: value.os.ordenServicio.numeroOS.toString(),
              especieValorada: value.os.ordenServicio.especieValorada.toString(),
              piezas: "1"
            };
          });
          console.log("ordenes", ordenes);
          const modal: insertarCubicacionMessage = {
            alto: values.alto.toString(),
            ancho: values.ancho.toString(),
            codigoOficina: props.store.getState().login.user.usuario.codigoOficina.toString(),
            codigoUsuario: props.store.getState().login.user.usuario.usuario.toString(),
            largo: values.largo.toString(),
            listaOrdenesServicio: ordenes,
            origen: "2",
            peso: values.peso.toString(),
          };
          console.log("envio", modal);
          props.store.dispatch(insertarCubicacion(modal));
        }
      }
    }

    if (!ok) {
      console.log('NO SE HAN INGRESADO OS');
      props.store.dispatch({
        type: MasivoActionTypes.FETCH_ERROR,
        mensaje: 'NO SE HAN INGRESADO OS'
      });
    }
  }

  props.store.subscribe(() => {
    if (props.store.getState().masivo.message !== undefined || props.store.getState().masivo.errors !== undefined) {
      if (props.store.getState().masivo.message !== undefined) {
        toast.info(props.store.getState().masivo.message);
      }
      if (props.store.getState().masivo.errors !== undefined) {
        toast.error(props.store.getState().masivo.errors);
      }
      const success: MasivoAction = {
        type: MasivoActionTypes.CLEAR_MESSAGE
      }
      props.store.dispatch(success);

    }
  });

  console.log("LOGGED IN", props.store.getState().login.loggedin);
  props.store.dispatch(reset('masivoForm'));
  const success: MasivoAction = {
    type: MasivoActionTypes.CLEAR_DATA
  }
  props.store.dispatch(success);

  if (!props.store.getState().login.loggedin) {
    props.store.dispatch(push('/login'));
  }
  return (
    <>
      <div>&nbsp;<ToastContainer /></div>
      <Col sm="12">
        <Form onSubmit={handleSubmit} isLoading={props.store.getState().masivo.loading} {...props} store={props.store} />
      </Col>
    </>
  );
}

export default connect(null)(Masivo);
